import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes, statusRoutes } from './constantRoutes'
import NProgress from 'nprogress' // progress bar
import store from '@/store'
import { startQiankun } from '@/micro'
import { addRouterGuard } from './routerGuard'

Vue.use(VueRouter)

// const router = new VueRouter({
//   mode: 'history',
//   scrollBehavior: () => ({ y: 0 }),
//   routes
// })

const newRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = newRouter()

router.whiteRoutes = routes
router.statusRoutes = statusRoutes

router.$addRoutes = (params) => {
  router.matcher = newRouter().matcher

  params.forEach((item, index) => {
    router.addRoute(item)
  })
}

addRouterGuard(router, store)

router.beforeResolve((to, from, next) => {
  // finish progress bar
  const { microApps } = store.state.app
  startQiankun(microApps)
  NProgress.done()
  next()
})

export default router
export {
  statusRoutes,
  routes as whiteRoutes
}
