import { Gateway } from 'ics-request'

export function getRequestGateway({ name, gateway }, proxyURL = '/api/', isDev) {
  // !注意： 网关会缓存在localStorage中，修改网关时需要先清除缓存
  const requestGateway = new Gateway({ name, gateway }, isDev)

  return (key = 'default') => {
    if (requestGateway.get(key) !== undefined) {
      const gatewayVal = requestGateway.get(key)
      return proxyURL + gatewayVal
    } else {
      console.error(`[getRequestGateway] key <${key}> is not defined`)
      return proxyURL
    }
  }
}
