import Vue from 'vue'

Vue.directive('drag', {
  bind: function(el, binding) {
    const { cache } = binding.value
    const dragEl = el
    let x = 0; let y = 0
    let clientX = 0; let clientY = 0
    dragEl.style.cursor = 'move'

    let cacheLocation = null
    if (cache) {
      cacheLocation = CacheLocation(cache, dragEl)
      cacheLocation.init()
    }

    function move(ev) {
      dragEl.style.left = (ev.clientX - clientX + x) + 'px'
      dragEl.style.top = (ev.clientY - clientY + y) + 'px'
    }
    function up() {
      if (cacheLocation) {
        cacheLocation.set()
      }
      document.removeEventListener('mousemove', move)
      document.removeEventListener('mouseup', up)
    }
    dragEl.addEventListener('mousedown', function(ev) {
      x = dragEl.offsetLeft
      y = dragEl.offsetTop
      clientX = ev.clientX
      clientY = ev.clientY
      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', up)
    })
  }
})

function CacheLocation(key, el) {
  const _key = key = `__drag-${key}`
  return {
    init() {
      const stateJson = localStorage.getItem(_key)
      if (stateJson) {
        const { left, top } = JSON.parse(stateJson)
        el.style.left = left
        el.style.top = top
      }
    },
    set() {
      const state = {
        left: el.style.left,
        top: el.style.top
      }
      localStorage.setItem(_key, JSON.stringify(state))
    }
  }
}
