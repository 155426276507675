import NProgress from 'nprogress' // progress bar
import {
  toLogin,
  getAppType,
  connectSocket,
  redirectRouter,
  getNotification,
  addRoutes
} from 'shared/sdk'
import { getPlatAsyncRoutes, layoutRoutes } from './asyncRoutes'

const microWhiteRoutes = ['/w/', '/auth/wechat/callback'] // 微应用白名单规则
let isFirst = true /** 是否第一次进入，还未调用接口 */

export function addRouterGuard(router, store) {
  NProgress.configure({ showSpinner: false }) // NProgress Configuration

  const { whiteRoutes, statusRoutes } = router

  router.beforeEach(async(to, from, next) => {
    // start progress bar
    NProgress.start()

    /** 路由性质：白名单、权限路由 */
    const isStatusPath = statusRoutes.find(route => route.path === to.path)
    const isWhitePath = isStatusPath || whiteRoutes.find(route => route.path === to.path)

    /** 重复地址 */
    if (to.fullPath === from.fullPath && to.path !== '/') {
      next(false)
      NProgress.done()
      return
    }

    /** 白名单微应用应用，从路由query获取应用信息 */
    const { _app } = to.query
    if (_app) {
      const whiteApp = JSON.parse(decodeURIComponent(atob(_app)))
      store.commit('app/SET_APP_INFO', [whiteApp])
    }

    /** 无需权限信息：登录、白名单 */
    if (to.path.startsWith('/login') || microWhiteRoutes.some(w => to.path.includes(w))) {
      if (to.path.startsWith('/login')) document.title = '登录'
      next()
      NProgress.done()
      return false
    }

    /** 授权过期 */
    const { serviceInfo } = store.state.app
    if (serviceInfo?.isExpire && to.path === '/no-auth') {
      next()
      NProgress.done()
      return
    }

    /** 权限过滤 */
    const { menus, userInfo } = store.state.user
    if (!isFirst || userInfo.phone) {
      /** 授权过期 */
      if (to.path === '/no-auth') {
        const { isExpire } = await store.dispatch('app/getServiceInfo')
        if (isExpire) {
          next()
          return
        }
      }
      connectSocket()
      redirectRouter(to, next, menus, { isWhitePath, isStatusPath })
    } else {
      try {
        isFirst = false
        const curAppType = getAppType()

        if (curAppType === 'organ') {
          await store.dispatch('organ/getOrganList')
          // 非必要数据 不阻塞页面渲染
          store.dispatch('organ/getOrganUserInfo')
        } else {
          await store.dispatch('organ/getOrganUserInfo') // 修复boss退出登录时无登录失效拦截
        }

        const [{ value: appMenus = [] }] = await Promise.allSettled([
          store.dispatch('user/getMenus'),
          store.dispatch('app/getApps'),
          curAppType === 'organ' && store.dispatch('organ/getOrganStyleInfo'),
          curAppType === 'organ' && store.dispatch('app/getServiceInfo')
        ])

        /** 权限路由添加 */
        if (appMenus?.length) {
          const platRoutes = getPlatAsyncRoutes()
          if (platRoutes?.length) addRoutes(appMenus, router, platRoutes, layoutRoutes)
        }
        redirectRouter(to, next, appMenus, { isWhitePath, isStatusPath, replace: true })
      } catch (error) {
        console.log('[ error ] >', error)
        if (error?.message?.code === 'A0230') {
          toLogin()
        } else if (error?.message?.code !== 'A0311') {
          // 修改接口异常都跳转至无权限页面
          next('/no-permission')
          return
        }
      }
    }
    NProgress.done()
  })

  router.afterEach((to, from) => {
    // 企业服务授权管理
    const serviceInfo = store.state.app.serviceInfo
    if (serviceInfo.isShow) {
      getNotification(serviceInfo, { sureFn: () => store.commit('app/TRIGGER_AUTH_DIALOG', true) })
    }
  })
}

