import 'core-js' // promise兼容
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import settings from '@/settings'
import api from '@/api'

import './plugins'
import './utils/directive'

import SaaSUI, { getDeployConfig, getPlatTitle } from 'shared'
Vue.use(SaaSUI, { store, api, OpenDevStorage: settings.OpenDevStorage })

import { setWindowGlobalConfig } from '@/utils'

Vue.config.productionTip = false

/** 获取部署配置 */
getDeployConfig(api.getDeployConfig).then(config => {
  store.commit('app/SET_DEPLOY_CONFIG', config)
  setWindowGlobalConfig(config?.domain)
  document.title = getPlatTitle()
  new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
  })
})
