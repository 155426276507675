<template>
  <div id="app">
    <div v-if="isMicroFull" id="frame" />
    <router-view v-else />
    <AuthDialog v-if="isShowAuthDialog" />
    <DevBlock v-show="isShowDev" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { OpenDevStorage } from './settings'

import DevBlock from '@/components/DevBlock'
export default {
  components: { DevBlock },
  data() {
    return { }
  },
  computed: {
    ...mapState('app', ['isShowAuthDialog']),
    isMicroFull() {
      return this.$route.path.includes('/container-app/') || this.$route.path.startsWith('/login')
    },
    isShowDev() {
      return this.$route.path !== '/devSettings' && location.pathname !== '/devSettings' && OpenDevStorage
    }
  },
  watch: {
    $route(from, to) {
      if (to.path === '/devSettings') {
        location.reload()
      }
    }
  },

  mounted() {
    /** 获取系统logo */
    this.$store.dispatch('app/getAppLogo')
  }
}
</script>

<style lang="scss">

</style>
