import Vue from 'vue'
import {
  addGlobalUncaughtErrorHandler,
  start,
  registerMicroApps
} from 'qiankun'

import store from '@/store'
import { getMicroCode } from 'shared/sdk'
import { getApps, lifeCycles } from './micro-app'

// 当前开启的微应用信息
let isStart

export const startQiankun = async(microApps = []) => {
  const curMicro = getMicroCode()

  if (isStart) return
  isStart = curMicro !== 'login' // 已经获取微应用数据并加载

  const apps = getApps(microApps)
  Vue.prototype.$apps = apps

  registerMicroApps(apps, lifeCycles)

  // 全局异常处理
  addGlobalUncaughtErrorHandler(event => {
    // if (event?.message) console.error(`${event.message}`)
    if (store.state.app.loading) store.commit('app/SET_LOADING', false)
  })

  // 启动服务
  start({
  // prefetch: 'all',
    fetch: window.fetch,
    excludeAssetFilter: (url) => {
      // 过滤 微应用 /assets 下js/css 动态加载拦截
      const appObj = apps.find(a => url.includes(a.entry + 'assets'))
      return appObj !== undefined
    }
  })
}

