<template>
  <div class="dev-tools-page">
    <div class="dev-tools-icon" @click="$router.push('/')"><i class="el-icon-back" /></div>
    <div class="dev-tools-tab">
      <!-- 菜单 -->
      <div>
        <div
          v-for="(item,index) in CacheKey"
          :key="item.name"
          :class="['dtt-item', index === curKeyIndex && 'active']"
          @click="curKeyIndex=index"
        >{{ item.name }}</div>
      </div>
      <!-- 本地/线上数据切换 -->
      <div class="dev-tools-btn">
        <el-radio-group v-model="stateType" size="mini">
          <el-radio-button label="本地" />
          <el-radio-button label="线上" />
        </el-radio-group>
      </div>
    </div>
    <!-- 内容 -->
    <div class="dev-tools-content">
      <DevResolveMenu v-show="curStateKey === 'resolveMenus'" />
      <vue-json-editor v-show="curStateKey !== 'resolveMenus'" v-model="state" mode="code" class="dtc-json" :show-btns="isUseStorage" :expanded-on-start="true" @json-save="onJsonSave" />
    </div>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import { mapState } from 'vuex'
import DevResolveMenu from './DevToolsPage/DevResolveMenu.vue'

const CacheKey = [
  {
    name: 'app',
    key: '__dev__app',
    stateKey: 'microApps'
  },
  {
    name: '菜单转换',
    key: 'resolveMenus'
  }
]
export default {
  name: 'DevToolsPage',
  components: { vueJsonEditor, DevResolveMenu },
  data() {
    return {
      CacheKey,
      curKeyIndex: 0,
      stateType: '本地'
    }
  },
  computed: {
    ...mapState('app', ['microApps']),
    curStateKey() {
      return CacheKey[this.curKeyIndex].key
    },
    // 是否展示本地数据
    isUseStorage() {
      return this.stateType === '本地'
    },
    state: {
      get() {
        if (this.isUseStorage) {
          const data = localStorage.getItem(this.curStateKey)
          try {
            return JSON.parse(data)
          } catch (error) {
            return data
          }
        } else {
          return this[CacheKey[this.curKeyIndex].stateKey]
        }
      },
      set(val) {
        if (this.isUseStorage) {
          // localStorage.setItem(this.curStateKey, JSON.stringify(val))
        }
      }
    }
  },
  methods: {
    // json保存
    onJsonSave(val) {
      localStorage.setItem(this.curStateKey, JSON.stringify(val))
      this.$message.success('保存成功，返回自动刷新')
    }
  }
}
</script>

<style lang="scss" scoped>
$btn-width: 46px;
$box-shadow:  0px 0px 10px #eeefff;
.dev-tools{
  &-icon{
    width: $btn-width;
    height: $btn-width;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $--color-primary;
    border-radius: 50%;
    color: $--color-white;
    margin-bottom: 20px;
    position: fixed;
    left: 30px;
    cursor: pointer;
    font-size: 24px;
  }
  &-page{
    padding: 50px 180px;
    box-sizing: border-box;
    color: $--color-text-primary;
  }
  &-tab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
    box-shadow: $box-shadow;
    .dtt-item{
      display: inline-block;
      padding: 10px 20px;
      line-height: 1.4;
      border-radius: 4px;
      background: $--background-color-base;
      font-weight: bold;
      cursor: pointer;
      &.active{
        background: $--color-primary;
        color: $--color-white;
      }
    }
    .dtt-item + .dtt-item{
      margin-left: 10px;
    }
  }
  &-btn{
    font-size: 14px;
    &__label{
      margin-right: 10px;
    }
  }
  &-content{
    margin-top: 20px;
    height: 800px;
    padding: 10px;
    box-shadow: $box-shadow;
    .dtc-json{
      height: 720px;
      ::v-deep .jsoneditor-vue{
        height: 100%;
      }
    }
  }
}

</style>
