/* Layout */
import Layout from '@/views/Layout'

import { ErrorPage, Redirect, About, NoAuth, UserProfile, MessageNotify, WeixinAuth } from 'shared/components'
import Version from '@/views/version'
import DevToolsPage from '@/components/DevToolsPage.vue'

export const statusRoutes = [
  {
    path: '/no-permission',
    name: 'NoPermission',
    component: ErrorPage,
    meta: { isOnlyContent: true, title: '无权限' }
  },
  {
    path: '/no-auth',
    name: 'NoAuth',
    component: NoAuth,
    meta: { isOnlyContent: true }
  }
]

export const routes = [
  {
    path: '/w/devSettings',
    name: 'DevPage',
    component: DevToolsPage
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/version',
    name: 'Version',
    component: Version
  },
  {
    path: '/userProfile',
    name: 'userProfile',
    component: UserProfile,
    meta: { title: '账号设置' }
  },
  {
    path: '/systeminfo',
    name: 'SystemInfo',
    component: () => import('@/views/systemAbout'),
    meta: { title: '关于系统' }
  },
  {
    path: '/auth/wechat/callback',
    name: 'RedirectPage',
    component: WeixinAuth
  },
  {
    path: '*',
    // name: 'Index', // 不添加name属性，防止addTagview生效
    component: Layout,
    children: [
      ...statusRoutes,
      {
        path: '/redirect/:path(.*)',
        name: 'RedirectPage',
        component: Redirect
      },
      {
        code: 'Message',
        name: 'Message',
        path: '/message/messageNotify',
        component: MessageNotify,
        meta: { title: '消息通知' }
      }
    ]
  }
]
