import axios from 'axios'
import request, { ConfigBaseURL, getBaseUrl } from '@/utils/request'

const basicOrganRequest = (url, opts, gatewayKey) => request({ baseURL: getBaseUrl(gatewayKey), url, method: 'get', ...opts })

export const app = {
  // 获取saas版本号
  getSaaSVersion: _ => request({
    baseURL: '/api',
    url: '/version',
    method: 'get'
  }),
  // 获取服务版本号
  getProVersion: _ => request({
    baseURL: ConfigBaseURL,
    url: '/service/version',
    method: 'get'
  }),
  // 获取前端版本号
  getMicroVersion: baseURL => request({ baseURL: baseURL, withCredentials: false, url: '/version.json' }),

  // 审核通过的应用列表数据(不分页)
  getMicroApps: () => {
    return request({
      baseURL: getBaseUrl('opendev'),
      url: '/app/approve/data',
      method: 'post',
      data: {
        data: {
          serviceType: 2 // 1:查询第三方应用则 2:查询基础模块则
        }
      }
    })
  },
  // 授权校验
  checkAuth: () => request({ url: 'organ/service/check_auth' }),
  // 更新授权
  updateCode: (data) => request({
    baseURL: ConfigBaseURL,
    method: 'post',
    url: '/auth/update_code',
    data: { data }
  }),
  // 更新授权
  getDeployConfig: _ => request({ baseURL: ConfigBaseURL, url: '/deploy/config' })
}

export const upload = ConfigBaseURL + 'cec-file/oss/upload'

// 用户
export const user = {
  getUserInfo: _ => request({
    baseURL: getBaseUrl('baseAccount'),
    url: '/account/current',
    method: 'get'
  }),
  // 查询菜单
  getSource: data => {
    const baseURL = data.appType === 'boss' ? getBaseUrl() : getBaseUrl('organ')
    return request({
      baseURL,
      url: '/source/current/tree',
      method: 'post',
      data: { data }
    })
  },
  loginOut() {
    return request({
      baseURL: getBaseUrl('baseAccount'),
      url: '/account/logout',
      method: 'get'
    })
  },
  updateUserHeadImage(data) {
    return request({
      baseURL: getBaseUrl('baseAccount'),
      url: '/account/current',
      method: 'post',
      data
    })
  },
  updateAccount: (data) => request({ baseURL: getBaseUrl('baseAccount'), url: '/account/update_current', method: 'post', data }),

  // 发送解绑微信验证码
  sendUnbindWeixinCode: data => request({ baseURL: getBaseUrl('baseAccount'), method: 'post', url: '/account/bind/send_unbind_weixin_code', data: { data: { phone: data }}}),
  // 解绑微信
  unbindWeixin: data => request({ baseURL: getBaseUrl('baseAccount'), method: 'post', url: '/account/bind/unbind_weixin', data }),
  // 绑定微信
  bindWeixin: data => request({ baseURL: getBaseUrl('baseAccount'), method: 'post', url: '/account/bind/bind_weixin', data }),
  // 请求微信授权uri
  getWeixinAuthUri: data => request({ baseURL: getBaseUrl('baseAccount'), method: 'post', url: '/account/login/authorize_by_weixin', data }),
  // 微信登录
  loginWeixin: data => request({ baseURL: getBaseUrl('baseAccount'), method: 'post', url: '/account/login/login_by_weixin', data })

}

// 机构
export const organ = {
  // 查询登录机构列表
  current: _ => basicOrganRequest(`/organ/list/current`),
  // 切换登录机构
  switchCurrent: (id) => basicOrganRequest(`/organ/switch/${id}`),
  // 获取初始机构
  oneCurrent: (id) => basicOrganRequest(`/organ/switch/init_tenant/${id}`),
  // 查询当前机构登录用户信息
  getOrganUserInfo: _ => basicOrganRequest('/user/current'),
  // 机构风格信息
  getOrganStyleInfo: _ => request({ baseURL: getBaseUrl('portal'), url: '/style', method: 'get' }),
  saveOrganStyleInfo: data => request({ baseURL: getBaseUrl('portal'), url: '/style', method: 'post', data })
}

// 消息推送
export const msg = {
  /** 消息类型列表 */
  getMessageType: data => request({ baseURL: getBaseUrl('msg'), url: '/messageType/list', method: 'POST', data }),
  /** 消息类型未读数 */
  getMessageTypeCount: data => request({ baseURL: getBaseUrl('msg'), url: '/messageNotification/typeGroupCount', method: 'POST', data }),

  // 消息通知-获取是否全部已读
  getAllReadState(data) {
    return request({
      baseURL: getBaseUrl('msg'),
      url: '/messageNotification/getAllReadState',
      method: 'POST',
      data,
      showTips: false
    })
  },
  getNotifyList(data) {
    return request({
      baseURL: getBaseUrl('msg'),
      url: '/messageNotification/topList',
      method: 'POST',
      data
    })
  },
  // [账号设置]获取-用户信息（邮箱、webhook）
  getInfo(data) {
    return request({
      baseURL: getBaseUrl('msg'),
      url: '/messageUserMsgInfo/getInfo',
      method: 'POST',
      data
    })
  },
  // [账号设置]保存-用户信息（邮箱、webhook）
  saveUserInfo(data) {
    return request({
      baseURL: getBaseUrl('msg'),
      url: '/messageUserMsgInfo',
      method: 'PUT',
      data
    })
  },
  // 消息通知-删除（批量）
  delNotifyList: (data) => request({
    baseURL: getBaseUrl('msg'),
    url: '/messageNotification',
    method: 'DELETE',
    data
  }),
  // 消息通知-已读（批量）
  setNotifyRead: (data) => request({
    baseURL: getBaseUrl('msg'),
    url: '/messageNotification/confirm',
    method: 'POST',
    data
  }),
  // 信息接收管理-全选情况
  getAllInfo: (data) => request({
    baseURL: getBaseUrl('msg'),
    url: '/messageReceivingSet/getAllInfo',
    method: 'POST',
    data
  }),
  // 信息接收管理-列表
  getMessageList: (data) => request({
    baseURL: getBaseUrl('msg'),
    url: '/messageReceivingSet/list',
    method: 'POST',
    data
  }),
  // 信息接收管理-修改    修改的类型一次传一个即可，即：typeId+任一
  saveMessageRece: (data) => request({
    baseURL: getBaseUrl('msg'),
    url: '/messageReceivingSet',
    method: 'PUT',
    data
  })

}

/** 获取logo地址 */
export async function getLogo(url) {
  const service = axios.create({
    baseURL: '/',
    responseType: 'blob'
  })
  let ret = false
  service.interceptors.response.use(response => {
    const { status, data } = response
    if (status === 200 && data && data.type.includes('image')) ret = URL?.createObjectURL(data) ?? ''
  })
  await service(url)
  return ret
}

export default {
  upload,
  getLogo,
  ...app,
  ...user,
  ...organ,
  ...msg
}
