<template>
  <section class="version-wrap">
    <div class="version-content">
      <header class="version-header">
        <h3>前端版本</h3>
      </header>
      <div class="version-list-box">
        <div v-for="(val, key) in frontVersions" :key="key" :class="[!val.version && 'error']" class="version-content inline inline-space bt">
          <div>{{ '- '+key }}</div>
          <div>{{ val.key || '---' }}</div>
          <div>{{ val.version || '---' }}</div>
          <div>{{ val.date || '---' }}</div>
        </div>
      </div>

      <header class="version-header">
        <h3>服务版本</h3>
      </header>
      <div class="version-list-box">
        <el-scrollbar style="height: 100%">
          <ul class="vl-list">
            <li v-for="(val, key) in serverVersions" :key="key" class="vl-item">
              <div class="version-name">{{ key }}：</div>
              <div v-for="version in val" :key="version.instanceId" class="version-content inline inline-space">
                <div>{{ '- '+ version.serviceId }}</div>
                <div :class="[version.error && 'error']">{{ version.version || version.error || '版本未知' }}</div>
                <div>{{ version.timestamp }}</div>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
  </section>
</template>
<script>
import mainVersionInfo from '../../public/version.json'

export default {
  data() {
    return {
      frontVersions: {},
      serverVersions: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      this.getServiceVersions()
      this.getMicroVersions()
    },

    // ************** 数据请求
    getServiceVersions() {
      this.$api.getProVersion().then(res => {
        this.serverVersions = res.data || {}
      })
    },

    async getMicroVersions() {
      const version = { '框架': { ...mainVersionInfo, key: mainVersionInfo.name, isCurrent: true }}

      for (let index = 0; index < this.$apps.length; index++) {
        const app = this.$apps[index]
        let res = {}
        try {
          res = await this.$api.getMicroVersion(app.entry)
        } catch (error) {
          console.log('[ error ] >', error)
        } finally {
          version[app.name] = { ...res, ...app, key: res.name }
        }
      }
      this.frontVersions = version
    }
  }
}
</script>

<style scoped lang="scss">

.version-wrap{
  padding: 20px;
  .bt{
    border-bottom: $--border-base;
    line-height: 36px;
  }
}
// ====== common =======
.inline{
  display: flex;
  align-items: center;

  &-space{
    justify-content: space-between;

    div{
      flex: 1;
    }
  }
}
.error{
  color: $--color-danger;
}

ul,li{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// ====== version ========
.version-list-box{
  padding: 20px;
}
.version-header{
  height: 50px;
  line-height: 50px;
  padding:0 20px;

  background: $--background-color-base;
  color: $--color-text-primary;
  font-size: 14px;
}
.vl-item{
  margin:0 20px;
  padding: 20px 10px;
  border-bottom: $--border-base;

  .version-name{
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
  }
  .version-content{
    padding: 0 20px;
    line-height: 1.4;
    color: $--color-text-secondary;
  }
}
</style>
