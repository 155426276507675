<template>
  <SaaSLayout
    ref="layout"
    :show-sidebar="isOnlyContent"
    :show-tag-view="isOnlyContent"
    :header="{
      dropdownList
    }"
  />
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { getCurPlat } from 'shared/sdk'

export default {
  name: 'Layout',
  computed: {
    isOnlyContent() {
      return !(this.$route?.meta?.isOnlyContent || getCurPlat() === 'unity')
    }
  },
  async mounted() {
    this.$nextTick(_ => {
      const { addTagView, delTagView } = this.$refs.layout.getEl()

      window.__fn__ = window?.__fn__ || {}
      Object.assign(window.__fn__, { addTagView, delTagView })
    })
  },
  methods: {
    dropdownList(list) {
      const _list = cloneDeep(list)
      const menus = [{
        id: '/systemInfo',
        name: '关于系统'
      }]
      _list.splice(-1, 0, ...menus)
      return _list
    }
  }
}
</script>

<style>

</style>
