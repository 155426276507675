import Request from 'ics-request'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import settings from '@/settings'
import {
  toLogin,
  asyncReload,
  asyncToIndex,
  getAppType,
  getRequestGateway
} from 'shared/sdk'

export const ConfigBaseURL = '/api/'

const gatewayConst = {
  default: `cec-saas-basic-manager-${getAppType()}`,
  baseAccount: 'cec-saas-basic-account',
  organ: 'cec-saas-manager-organ',
  opendev: 'cec-open-dev',
  msg: 'cec-saas-unified-msg-push-server',
  upload: 'cec-file/oss/upload',
  portal: 'cec-saas-basic-portal' // 风格设置
}

// 错误码配置
const errorEvent = {
  'A0507': (data) => {
    data?.message?.message && Message.error(data.message.message)
    asyncReload()
  },
  'A0230': () => toLogin(),
  'A4000': () => asyncToIndex(),
  'A0311': () => router.replace('/no-auth')
}

export const getBaseUrl = getRequestGateway({ name: 'Gateway:saas-master-basic', gateway: gatewayConst }, ConfigBaseURL, settings.OpenDevStorage)

const request = Request({
  baseURL: getBaseUrl(),
  withCredentials: true,
  timeout: 1000 * 60,
  callbackAll: true // 返回所有数据（包含接口状态、数据、信息） ，为false时只返回data对象
}, errorEvent)

// 请求拦截
request.interceptors.request.use(config => {
  if (store.state.organ.currentOrganID) config.headers['tenant-id'] = store.state.organ.currentOrganID || ''
  return config
})

// 响应拦截
request.interceptors.response.use()

export default request

export {
  request
}
