import { StyleSet } from 'shared/components'
import { getCurPlat } from 'shared'
import Layout from '@/views/Layout'

export const asyncRoutes = {
  common: [],
  unity: [{
    path: '/styleSet',
    name: 'StyleSet',
    component: StyleSet
  }]
}

export const layoutRoutes = {
  path: '*',
  component: Layout,
  children: []
}
export function getPlatAsyncRoutes(plat = getCurPlat()) {
  return asyncRoutes.common.concat(asyncRoutes[plat] || [])
}
