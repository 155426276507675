
let _config = null

export async function getDeployConfig(api) {
  try {
    if (_config) return _config
    _config = { plan: 'A' }

    const ret = await api()
    _config = { ..._config, ...ret?.data }
    return _config
  } catch (_) {
    return _config
  } finally {
    window.__saas_config__ = _config
  }
}
