<template>
  <div v-drag="{ cache: 'devtools' }" class="dev-wrap">
    <div class="dev-title">
      <div>
        <span>本地调试</span>
        <i class="icon el-icon-setting" @click.prevent.stop="handleRouterGo" />
      </div>
      <span class="dev-icon--setting">
        <i class="el-icon-arrow-down" @click="isShowContent = !isShowContent" />
      </span>
    </div>
    <transition name="fade">
      <div v-show="isShowContent" class="dev-content">
        <div class="dev-content-item">
          <span>是否开启</span>
          <el-switch
            v-model="isUseStorage"
            class="dev-form--switch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleChange"
          />
          <el-tooltip class="el-icon-tooltip" effect="dark" content="修改后刷新生效" placement="top-start">
            <i class="el-icon-question" />
          </el-tooltip>
          <!-- <span class="dev-icon--setting"><i v-show="isUseStorage" class="el-icon-setting" @click="$router.push('/devSettings')" /></span> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getDevConfig, setDevConfig } from 'shared'
export default {
  data() {
    return {
      isUseStorage: getDevConfig(),
      isShowContent: true
    }
  },
  methods: {
    handleChange(val) {
      setDevConfig(val)
      this.isUseStorage = getDevConfig()
    },
    handleRouterGo() {
      const goName = 'DevPage'
      if (this.$route.name === goName) {
        return false
      }
      const routeLocation = this.$router.resolve({ name: goName })
      window.open(routeLocation.href, '__blank')
    }
  }

}
</script>

<style lang="scss" scoped>
.dev-wrap{
  position: fixed;
  z-index: 999;
  right: 0px;
  top: 80%;
  width: 140px;

  color: $--color-white;
  // border-radius: 4px;

  font-size: 14px;
  .dev-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 5px;
    background: $--color-primary;
    .icon{
      margin-left: 4px;
    }
  }
  .dev-content{
    padding: 10px 6px;
    background: $--color-primary;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
    .dev-form--switch{
      transform: scale(0.8);
    }
    .dev-icon--setting{
      width: 14px;
    }
  }
  i[class^="el-icon-"],.el-icon-tooltip{
    cursor: pointer;
  }
}

/* fade */
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) ;
  transform-origin: center top;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: scaleY(0);
}

</style>
