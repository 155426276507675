export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export * from './request'

/** 平台地址、类型配置 */
export function setWindowGlobalConfig(config) {
  // 开发环境默认配置
  const defaultWindowConfig = {
    /** 登录地址 */
    login: 'http://saas-login-front.saas-dev.192.168.4.124.nip.io:32140',
    /** 统一门户地址 */
    unity: 'http://saas-unity-front.saas-dev.192.168.4.124.nip.io:32140',
    /** 当前平台类型 */
    CUR_PLAT: 'organ'
  }
  const domain = config || defaultWindowConfig
  const { login, unity } = domain
  domain.CUR_PLAT = Object.keys(domain)?.find(key => domain[key] === location.origin) || defaultWindowConfig.CUR_PLAT
  domain.loginUrl = login
  domain.unityUrl = unity
  domain.microUrl = unity + '/subapp/' // saas微应用地址
  window.global_config = domain
}
