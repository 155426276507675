<template>
  <div class="dtp-menus">
    <!-- <div class="dtp-menus-radio">
      <el-radio-group v-model="stateType" size="mini">
        <el-radio-button v-for="item in radioOptions" :key="item.label" :label="item.label" />
      </el-radio-group>
      <el-input v-if="stateType === '路由'" v-model="routeCode" size="mini" placeholder="请输入应用编码" />
    </div> -->
    <div class="dtp-menus-main ics-flex_center">
      <div class="dtp-menus-item">
        <vueJsonEditor ref="sourceJsonEditor" v-model="sourceData" mode="code" class="dtc-json" @json-change="sourceJsonChange" @has-error="sourceHseError" />
      </div>
      <div class="dtp-menus-item">
        <vueJsonEditor v-model="resolveData" mode="code" class="dtc-json" />
      </div>
    </div>
    <div class="dtp-menus-footer">
      <el-button type="primary" @click="handleResolve">转换</el-button>
      <el-button type="primary" @click="handleCopy(resolveData)">复制</el-button>
    </div>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
const radioOptions = [{ label: '开放平台菜单', fun: 'resolveMenus' }, { label: '路由', fun: 'resolveRoutes' }]
export default {
  name: 'DevResolveMenu',
  components: { vueJsonEditor },
  data() {
    return {
      sourceData: [],
      resolveData: [],
      radioOptions,
      routeCode: '',
      stateType: radioOptions[0].label
    }
  },
  methods: {
    handleResolve() {
      const fun = radioOptions.find(r => r.label === this.stateType).fun
      console.log('%c [ this.sourceData ]-43', 'font-size:13px; background:pink; color:#bf2c9f;', this.sourceData)
      const ret = this[fun](this.sourceData)
      this.resolveData = ret
    },
    handleCopy() {
      const input = document.createElement('input')
      input.value = JSON.stringify(this.resolveData)
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      input.remove()
    },
    sourceJsonChange(data) {
      console.log('%c [ sourceJsonChange ]-56', 'font-size:13px; background:pink; color:#bf2c9f;', data)
    },
    sourceHseError(data) {
      console.log('%c [ sourceHseError ]-59', 'font-size:13px; background:pink; color:#bf2c9f;', data, this.$refs.sourceJsonEditor)

      console.log('%c [  ]-61', 'font-size:13px; background:pink; color:#bf2c9f;', this.$refs.sourceJsonEditor.editor.get())
      // this.sourceData = JSON.stringify(data)
    },
    resolveMenus(data) {
      const retKeys = ['code', 'name', 'type', 'extra', 'cache']
      return data?.map(item => {
        const temp = {}
        if (item.id) {
          retKeys.forEach(key => {
            if (item[key]) temp[key] = item[key]
          })
        }
        if (item.children) temp.children = this.resolveMenus(item.children)
        return temp
      })
    },
    resolveRoutes(data, code) {
      return data.map(item => {
        const { name, path, meta } = item
        const temp = {
          code: name,
          name: meta.title,
          resourceType: 'pc',
          extra: {
            path: code ? `/${code}${path}` : path,
            ...meta
          }
        }
        if (item.children) temp.children = this.resolveRoutes(item.children)
        return temp
      })
    }
  }

}
</script>

<style lang="scss" scoped>
$footer-height :80px;

.dtp-menus{
  height: 100%;
  overflow: hidden;
}

.dtp-menus-radio{
  margin-bottom: 10px;
  ::v-deep .el-input{
    width: 120px;
    display: inline-block;
    margin-left: 10px;
  }
}
.dtp-menus-main {
  height: calc(100% - $footer-height);
  .dtp-menus-item{
    flex: 1;
    height: 100%;
  }
  .dtp-menus-item + .dtp-menus-item{
    margin-left: 40px;
  }
  .dtc-json{
    height: 100%;
    ::v-deep .jsoneditor-vue{
      height: 100%;
    }
    ::v-deep .jsoneditor-poweredBy{
      display: none;
    }
  }
}

.dtp-menus-footer{
  line-height: $footer-height;
  text-align: center;
}
</style>
