import NProgress from 'nprogress'
import store from '@/store'
import pkg from '@/../../package.json'
import {
  request
} from '@/utils'

import { resolveProps, toLogin, getPlatInfo, localStorageChange, getRequestGateway } from 'shared/sdk'

export function getApps(microApps) {
  const _apps = [{
    'name': '登录',
    'entry': window.global_config.loginUrl || '',
    'container': '#frame',
    'activeRule': '/login'
  }]

  const appData = localStorageChange('app', _apps.concat(microApps))

  const { appType, curType } = getPlatInfo()
  const props = (app) => ({
    action: {
      toLogin,
      getRequestGateway,
      request,
      resolveProps
    },
    root: {
      version: pkg.version,
      name: curType,
      appType
    },
    microStore: store,
    routerBase: app.activeRule
  })

  // app 数据处理
  return appData.map(app => ({
    ...app,
    // loader: loader => store.commit('app/SET_LOADING', loader), // bug:27712 未完全加载状态不变化
    props: props(app)
  }))
}

export const lifeCycles = {
  beforeMount: app => {
    store.commit('app/SET_LOADING', true)
    NProgress.start()
  },
  afterMount: app => {
    store.commit('app/SET_LOADING', false)
    NProgress.done()
  }
}
